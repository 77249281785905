@use "colors";
@use "typography";
@import "custom";
@import "bootstrap/scss/navbar";

.equifit-summary {
  color: $white;
  line-height: 1.15;
  margin: 150px auto;

  video {
    block-size: auto;
    display: block;
    inline-size: auto;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    min-block-size: 100%;
    min-inline-size: 100%;
    position: fixed;
    transform: translate(-50%, -50%);
    z-index: -100;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .bg-img {
    display: none;

    @include media-breakpoint-down(sm) {
      background-attachment: fixed;
      background-position: top;
      background-repeat: no-repeat;
      display: block;
      inline-size: 100%;
      inset-block-start: -114px;
      inset-inline: 0;
      position: fixed;
      z-index: -100;
    }
  }

  .large-headline {
    font-size: 40pt;
    line-height: 1;
    margin: auto;
    margin-block-start: 80px;
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
      font-size: 30pt;
    }
  }

  .section-subtitle {
    display: block;
    font-size: 20px;
    line-height: 1.1;
    margin: 0 auto;
    max-inline-size: 600px;
    padding: 20px 0;
    text-align: center;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }

  .section-title {
    margin-block-start: 160px;

    @include media-breakpoint-down(sm) {
      font-size: 30pt;
      margin-block-start: 130px;
    }
  }

  .error-wrapper {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-box-pack: center;
    display: flex;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;

    .error {
      margin: 0 auto;
      position: relative;
      text-align: center;
    }

    .large-headline {
      font-size: 40pt;
      line-height: 1;
      margin: auto;
      text-align: center;
      text-transform: uppercase;
    }

    .section-title {
      margin-block-start: 20px;
    }

    .section-subtitle {
      display: block;
      font-size: 20px;
      margin: 0 auto;
      max-inline-size: 600px;
      padding: 20px 0;
      text-align: center;
    }

    .error-message {
      color: $red;
      font-size: 0.5em;
      padding-block-start: 3em;

      p {
        margin-block-start: 10px;
      }
    }
  }

  .error-section {
    @include media-breakpoint-down(sm) {
      overflow-x: hidden;
    }

    .error-wrapper {
      block-size: 54vh;
      -webkit-box-direction: normal;
      -webkit-box-orient: vertical;
      -webkit-box-pack: center;
      display: flex;
      flex-direction: column;
      -ms-flex-pack: center;
      justify-content: center;

      .error {
        margin: 0 auto;
        position: relative;
        text-align: center;
      }

      .large-headline {
        font-size: 40pt;
        line-height: 1;
        margin: auto;
        text-align: center;
        text-transform: uppercase;
      }

      .section-title {
        margin-block-start: 20px;
      }

      .section-subtitle {
        display: block;
        font-size: 20px;
        line-height: 1.1 !important;
        margin: 0 auto;
        max-inline-size: 600px;
        padding: 20px 0;
        text-align: center;

        @include media-breakpoint-down(sm) {
          max-inline-size: 383px;
        }
      }

      .error-message {
        color: $red;
        font-size: 0.5em;
        padding-block-start: 3em;

        p {
          margin-block-start: 10px;
        }
      }
    }
  }

  header {
    background-color: $bg-header;

    .main-header {
      inline-size: 100%;
      inset-block-start: 0;
      inset-inline-start: 0;
      min-block-size: 50px;
      position: fixed;
      z-index: 9995;
    }

    .navbar {
      background-color: $bg-header;
      display: flex;
      inline-size: 100%;
      inset-block-start: 0;
      inset-inline-start: 0;
      justify-content: space-between;
      position: fixed;
      z-index: 1030;

      @include media-breakpoint-up(lg) {
        box-sizing: border-box;
        min-block-size: 60px;
        padding-inline: 15px;
      }
    }

    .eqx-logo {
      block-size: 15px;
      inline-size: 120px;
    }

    .navbar-brand {
      display: block;
      inline-size: 125px;
      margin: 0 auto;
      padding: 21px 0 13px;

      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }
  }

  .scroll-hint {
    font-size: 20px;
    margin-block-start: 50px;
    text-align: center;

    .arrow {
      animation: forward 2s;
      animation-delay: 0.3s;
      animation-timing-function: ease-in-out;
      block-size: 20px;
      inline-size: 20px;
      margin-inline-end: 7px;
      position: relative;
      transform: rotate(90deg);
    }
  }

  .content {
    inline-size: 75%;
    margin-inline: auto;
    padding: 0 20px;

    @include media-breakpoint-down(sm) {
      inline-size: 98.5%;
      margin-inline: unset;
      padding: 0;
    }
  }

  .small-label,
  .chart-text {
    font-size: 15px;
    text-transform: uppercase;
  }

  .intro {
    margin: 0 auto;
    padding-block-start: 30px;
    text-align: center;

    .divider {
      inline-size: 100vw;
      margin-inline-start: calc(-50vw + 50%);

      .divider-line {
        background-size: 100%;
        -webkit-box-pack: center;
        display: flex;
        -ms-flex-pack: center;
        inline-size: 100vw;
        justify-content: center;
        margin: 100px auto 0;
      }
    }

    .small-label {
      font-size: 15px;
      margin-block-end: 10px;
      text-align: center;
      text-transform: uppercase;
    }

    .small-headline {
      font-size: 30px;
      margin: 0 5px;
      margin-block-end: 30px;
      text-align: center;
      text-transform: uppercase;

      @include media-breakpoint-down(sm) {
        font-size: 26px;
        line-height: 1.2;
      }
    }
  }

  .goals {
    -webkit-box-pack: center;
    display: flex;
    -ms-flex-pack: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-block-start: 60px;
    margin-inline: auto;

    .goal {
      align-items: center;
      display: flex;
      flex-direction: column;
      inline-size: 50%;
      justify-content: flex-start;

      @include media-breakpoint-up(lg) {
        inline-size: 33%;
      }

      .small-headline {
        inline-size: 88%;
        line-height: 1.15;

        @include media-breakpoint-down(sm) {
          @include typography.subhead-semi-bold;

          color: colors.$white;
          inline-size: unset;
        }
      }

      .items-center {
        align-items: center;
      }

      .goal-img {
        block-size: auto;
        inline-size: 40%;

        @include media-breakpoint-down(sm) {
          inline-size: 75%;
        }

        @include media-breakpoint-down(md) {
          inline-size: 75%;
        }
      }
    }
  }

  .fms-section {
    margin: 150px auto;

    @include media-breakpoint-down(sm) {
      margin: 0;
      padding: 0 6px 0 12px;
    }

    .wrapper {
      align-items: center;
      block-size: 660px;
      -webkit-box-align: center;
      -webkit-box-direction: normal;
      -webkit-box-orient: vertical;
      -webkit-box-pack: center;
      display: flex;
      -ms-flex-align: center;
      -ms-flex-pack: center;
      justify-content: center;

      @include media-breakpoint-down(sm) {
        block-size: 1064px;
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        flex-flow: column nowrap;
      }

      .titles {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        inline-size: 50%;
        line-height: 1;
        margin: -20px 20px 50px 0;
        order: 1;
        text-align: center;
        text-transform: uppercase;

        @include media-breakpoint-down(sm) {
          inline-size: auto;
          margin: auto;
          margin-block-end: 60px;
        }
      }

      .scores {
        -webkit-box-ordinal-group: 4;
        display: block;
        -ms-flex-order: 3;
        inline-size: 50%;
        margin: 0;
        margin-inline-start: 20px;
        max-inline-size: 735px;
        order: 3;

        @include media-breakpoint-down(sm) {
          -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
          font-size: 13pt !important;
          inline-size: 100%;
          margin-inline-start: 0;
          order: 2;
        }

        .total {
          padding: 20px 0;
        }

        .score {
          block-size: 92px;
          border-block-start: 2px solid;
          border-image: linear-gradient(90deg, $bar-color1, $bar-color6);
          border-image-slice: 1;
          border-style: solid;
          border-width: 2px 0 0;
          -webkit-box-pack: space-evenly;
          display: flex;
          inline-size: 100%;
          padding-block-end: 0;

          @include media-breakpoint-down(sm) {
            font-size: 13pt !important;
          }

          .icon {
            align-items: center;
            -webkit-box-align: center;
            -webkit-box-direction: normal;
            -webkit-box-orient: vertical;
            -webkit-box-pack: center;
            display: flex;
            -ms-flex-align: center;
            flex-flow: column;
            -ms-flex-pack: center;
            inline-size: 40%;
            justify-content: center;

            .fms-img {
              block-size: auto;
              inline-size: 50px;
              margin-block-end: -5px;

              @include media-breakpoint-down(sm) {
                display: none;
              }
            }

            .small-headline {
              font-size: 14pt;
              inline-size: 100%;
              margin-block-end: 10px;
              text-align: center;
              text-transform: uppercase;

              @include media-breakpoint-down(sm) {
                font-size: 13pt;
              }
            }
          }

          .values {
            -webkit-box-pack: end;
            display: flex;
            -ms-flex-pack: end;
            inline-size: 60%;
            justify-content: flex-end;

            .sides {
              align-items: flex-end;
              display: flex;
              flex-flow: column;
              inline-size: 50%;
              justify-content: center;
              text-transform: uppercase;
            }

            .small-headline {
              font-size: 14pt;

              span {
                opacity: 0;

                &.value-span {
                  color: $white;
                  opacity: 1;
                  transition: opacity 5s ease-in-out;
                }
              }

              &.value {
                align-items: center;
                display: flex;
                inline-size: 50%;
                justify-content: center;
                margin: 0;
              }

              &.left {
                margin-block-end: 10px;
              }

              &.left,
              &.right {
                inline-size: 60%;
              }
            }
          }
        }
      }
    }
  }

  .cols {
    .col {
      display: inline-block;
      padding: 0 30px;
    }
  }

  .segmental-lean-section {
    .chart {
      inset-block-start: 20px;
      position: relative;
    }

    .chart-title {
      .chart-label {
        font-size: 15px;
      }
    }

    .human {
      text-align: center;

      .human-img {
        block-size: 360px;
        margin: 60px 0 0;
      }
    }

    .body-text {
      max-inline-size: 611px;

      @include media-breakpoint-down(sm) {
        font-size: 14px;
        max-inline-size: 80%;
      }
    }

    .segments {
      inline-size: 100%;
      inset-block-start: 62px;
      position: absolute;

      .segment {
        min-inline-size: 150px;
        position: absolute;
        text-align: center;

        &.trunk {
          inset-block-start: -60px;
          margin: auto;
          text-align: center;
        }
      }

      .la {
        margin-block-start: 9px;
        margin-inline-start: -210px;
      }

      .ra {
        margin-block-start: 9px;
        margin-inline-start: 211px;
      }

      .ll {
        margin-block-start: -28px;
        margin-inline-start: -162px;
      }

      .rl {
        margin-block-start: -28px;
        margin-inline-start: 159px;
      }

      .row1 {
        -webkit-box-pack: center;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
      }

      .row2 {
        inset-block-start: 110px;
      }

      .row3 {
        inset-block-start: 245px;
      }

      .row2,
      .row3 {
        -webkit-box-pack: space-evenly;
        display: flex;
        -ms-flex-pack: space-evenly;
        inline-size: 100%;
        justify-content: space-evenly;
        position: absolute;
      }
    }
  }

  .chart-title {
    font-size: 30px;
    text-transform: uppercase;

    &.value {
      border: 2px solid $bar-color1;
      margin: 40px auto 5px;
      padding: 10px 20px;
    }
  }

  // slidder css
  .graph {
    align-items: center;
    -webkit-box-align: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-box-pack: center;
    display: flex;
    -ms-flex-align: center;
    flex-flow: column;
    -ms-flex-pack: center;
    inline-size: 50%;
    justify-content: center;

    @include media-breakpoint-down(md) {
      inline-size: 100%;
    }

    @include media-breakpoint-down(sm) {
      inline-size: 100%;
      margin-block-start: 60px;
    }

    &.w-60 {
      inline-size: 60%;

      @include media-breakpoint-down(sm) {
        inline-size: 100%;
      }
    }

    .small-label {
      margin-block: 10px 20px;
    }

    .detail {
      margin: 0 auto;

      @include media-breakpoint-down(md) {
        inline-size: 100%;
      }

      .small-label {
        font-size: 15px;
      }
    }

    .tc {
      text-align: center;
    }

    .chart-title {
      font-size: 30px;
      text-transform: uppercase;
    }

    .barvis {
      margin-block-start: 50px;
      position: relative;

      .bars {
        display: flex;
        margin: 0 auto 20px;
        max-inline-size: fit-content;
        position: relative;

        .indicator {
          block-size: 0;
          border-block-start: 20px solid $bar-color1;
          border-inline-end: 10px solid transparent;
          border-inline-start: 10px solid transparent;
          inline-size: 0;
          inset-block-start: -30px;
          inset-inline-start: -10px;
          position: absolute;
          transform: translate(0);
          transition: 2s;

          &.color1 {
            background: none;
            border-block-start-color: $bar-color1;
          }

          &.color2 {
            background: none;
            border-block-start-color: $bar-color2;
          }

          &.color3 {
            background: none;
            border-block-start-color: $bar-color3;
          }

          &.color4 {
            background: none;
            border-block-start-color: $bar-color4;
          }

          &.color5 {
            background: none;
            border-block-start-color: $bar-color5;
          }

          &.color6 {
            background: none;
            border-block-start-color: $bar-color6;
          }
        }

        .step {
          inline-size: 50px;
          margin-inline-end: 10px;

          .bar {
            background: $white;
            block-size: 6px;
            inline-size: 100%;
            margin-block-end: 15px;
            transition: 1s;

            &.color1 {
              background: $bar-color1;
            }

            &.color2 {
              background: $bar-color2;
            }

            &.color3 {
              background: $bar-color3;
            }

            &.color4 {
              background: $bar-color4;
            }

            &.color5 {
              background: $bar-color5;
            }

            &.color6 {
              background: $bar-color6;
            }
          }

          .name,
          .domain {
            font-size: 7pt;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .body-composition-section {
    inline-size: 100vw;
    margin-inline-start: calc(-50vw + 50%);
    padding: 0 20px;

    .details {
      justify-content: center;
      margin-block-start: 25px;

      @include media-breakpoint-down(sm) {
        margin-block-start: 60px;
      }

      @include media-breakpoint-down(md) {
        margin-block-start: 60px;
      }

      .w-40-l {
        inline-size: 40%;
      }

      .w-100 {
        @include media-breakpoint-down(md) {
          inline-size: 100%;
        }

        @include media-breakpoint-down(sm) {
          inline-size: 100%;
        }
      }

      .body-composition-grid {
        border: 1px solid $white;
        display: flex;

        .small-label {
          @include media-breakpoint-down(md) {
            font-size: 12px;
          }

          @include media-breakpoint-down(sm) {
            font-size: 12px;
          }
        }

        .column {
          -webkit-box-direction: normal;
          -webkit-box-orient: vertical;
          display: flex;
          flex-flow: column;
          inline-size: 33.333%;

          .cell {
            align-items: middle;
            -webkit-box-align: center;
            display: flex;
            -ms-flex-align: center;
            flex-flow: column;
            justify-content: center;
            line-height: 1.15;
            padding: 20px 10px;
            text-align: center;
          }

          &.column2 {
            .cell {
              block-size: 66.6667%;
              border: 1px solid $white;
              border-width: 0 1px 1px 0;
            }
          }

          &.column3 {
            .cell {
              block-size: 66.6667%;
            }
          }

          &.column1 {
            border-inline-end: 1px solid $white;

            .cell {
              block-size: 33.3333%;
              border-block-end: 1px solid $white;

              &:last-of-type {
                border-block-end: 0;
              }
            }
          }
        }
      }

      .detail {
        &:first-of-type {
          margin-inline-end: 40px;

          @include media-breakpoint-down(md) {
            margin-block-end: 40px;
            margin-inline-end: 0;
          }

          @include media-breakpoint-down(sm) {
            margin-block-end: 40px;
            margin-inline-end: 0;
          }
        }
      }
    }
  }

  .basic-measurements {
    .details {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      margin-block-start: 20px;

      .human {
        inline-size: 40%;

        .human-fig {
          block-size: 420px;
          float: right;
          position: relative;
        }

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      .graph {
        .detail {
          &:first-of-type {
            margin-block-end: 43px;
          }
        }
      }
    }
  }

  .flex {
    -webkit-box-pack: center;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .chart-unit {
    font-size: 15px;
  }
}

#sticky-buy-training {
  background: rgb(0 0 0 / 73%);
  block-size: 65px;
  color: $white;
  font-size: 24px;
  font-weight: 700;
  inline-size: 100%;
  inset-block-end: 0;
  inset-inline-start: 0;
  padding: 15px 0 10px;
  position: fixed;
  text-align: center;

  a {
    border: 2px solid $white;
    inline-size: 100px;
    margin: auto;
    padding: 3px 30px;
    text-decoration: none;

    &:hover {
      background-color: $white;
      color: $black;
    }
  }
}

@keyframes forward {
  0% {
    margin-block-start: 4px;
  }

  50% {
    margin-block-start: 7px;
  }

  100% {
    margin-block-start: 4px;
  }
}

@media (width <= 650.98px) {
  .equifit-summary .segmental-lean-section .segments .segment {
    min-inline-size: auto !important;
  }

  .equifit-summary .segmental-lean-section .segments .la {
    margin-inline-start: -135px !important;
  }

  .equifit-summary .segmental-lean-section .segments .ra {
    margin-inline-start: 135px !important;
  }

  .equifit-summary .bg-img {
    min-inline-size: 100vw !important;
  }
}

@media (width <= 413px) {
  .equifit-summary .segmental-lean-section .segments .ll {
    margin-inline-start: -127px !important;
  }

  .equifit-summary .segmental-lean-section .segments .rl {
    margin-inline-start: 132px !important;
  }
}

@media (width <= 650px) {
  .equifit-summary-la {
    margin-inline-end: 80px !important;
  }

  .equifit-summary-ra {
    margin-inline-start: 80px !important;
  }
}
